<template>
    <div class="jump-page">
        <div v-if="loading" class="loading">
            <img class="loading-img" :src="jumpLoading" alt="jumpLoading">
        </div>
        <!-- <div v-if="error">
            <img class="loading-img" :src="jumpLoading" alt="jumpLoading"></img>
        </div> -->
    </div>
</template>
<script>
export default {
    name: "JumpPage",
    data () {
        return {
            loading: true,
            error: false,
        }
    },
    created () {
        this.init()
    },
    computed: {
        jumpLoading () {
            return require('@/assets/images/jump-loading.gif');
        }
    },
    methods: {
        init () {
            this.initAssignment();
        },
        initAssignment () {
            const userId = this.$route.query.userId;
            const code = this.$route.query.code;

            this.initPageData(userId, code)
        },
        initPageData(userId, code) {
            console.debug(userId, code,"userId, code");
            if (userId && code) {
                this._fet("/school/login/xkwAuthentication", {userId: userId, code: code}).then((res) => {
                    if (res.data.code == '200') {
                        console.log(res.data,'回调')
                        window.open(res.data.data, "_blank");
                        setTimeout(() => {
                            this.$router.push({
                                name: "SEduSeduEduSource"
                            })
                        }, 200)
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg)
                        setTimeout(() => {
                            this.$router.push({
                                name: "SEduSeduEduSource"
                            })
                        }, 200)
                    }
                })
            } else {
                this.loading = false;
                this.error = true;
                this.$message.error('访问出错')
                this.$router.push({
                    name: "SEduSeduEduSource"
                })
            }

        }
    }

}
</script>
<style lang="scss" scoped>
    .jump-page {
        width: 100%;
        height: 100%;
        .loading {
            width: 100%;
            height: 100%;
            .loading-img {
                display: block;
                margin: 200px auto;
            }

        }
    }
</style>>
</style>
