<template>
    <!-- 家校互联 > 信息采集 -->
    <div style="height: calc(100vh - 75px)">
        <component
            v-bind:is="currentTabComponent"
            :formData="formData"
            @changeComp="changeComp"
            :type="type"
            :formName="formName"
            :formType = "formType"
            :fillStatus="fillStatus"
            :releaseStatus="releaseStatus"
        ></component>
    </div>
    <!-- <interconnection-info-collection /> -->
</template>

<script>
import InterconnectionInfoCollection from "../components/scrollWrapper/InterconnectionInfoCollection";
import InterconnectionInfoCollectionStatostics from "../components/scrollWrapper/InterconnectionInfoCollectionStatostics";
import CreatForm from "@/components/scrollWrapper/InterconnectionInfoCollection/CreatForm.vue";
export default {
    components: {
        InterconnectionInfoCollection,
        InterconnectionInfoCollectionStatostics,
        CreatForm,
    },
    data() {
        return {
            currentTabComponent: "InterconnectionInfoCollection",
            formData: "",
            type: "",
            formType:'',
            formName:"",
            fillStatus:"",
            releaseStatus: "",
        };
    },
    methods: {
        changeComp(val) {
            let {component, data, type} = val;
            this.type = type;
            this.formData = data.id;
            this.formType = data.formType;
            this.formName = data.formName;
            this.fillStatus = data.fillStatus;
            this.releaseStatus = data.releaseStatus;
            if (component == "CreatForm") {
                this.$eventDispatch("setGlobalPageType", "form");
            } else {
                this.$eventDispatch("setGlobalPageType", "list");
            }
            this.currentTabComponent = component;
        },
    },
};
</script>

<style scoped>
</style>
