<template>
  <!--家校互联>首页轮播图-->
  <interconnection-swiper-scroll-wrapper/>
</template>

<script>
import InterconnectionSwiperScrollWrapper from "@/components/scrollWrapper/InterconnectionSwiper";

export default {
  name: "InterconnectionSwiper",
  components: {
    InterconnectionSwiperScrollWrapper
  }
}
</script>

