<template>
  <!-- 家校互联 > 家长通讯录 -->
  <interconnection-parent-directory-scroll/>
</template>

<script>
import InterconnectionParentDirectoryScroll from '@/components/scrollWrapper/InterconnectionParentDirectory'
export default {
  name: "InterconnectionParentDirectory",
  components: {
    InterconnectionParentDirectoryScroll
  }
}
</script>

<style scoped>

</style>
