<!--
 * @Author: pengyinlong 484964154@qq.com
 * @Date: 2024-06-12 10:04:01
 * @LastEditors: pengyinlong 484964154@qq.com
 * @LastEditTime: 2024-06-19 16:46:38
 * @FilePath: \cloud_campus_Front-end\school_end\src\views\InterconnectionSchoolNotice.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <!-- 家校互联->信息发布 -->
  <div class="interconnection-school-notice-layout">
    <!-- tabs -->
    <template v-if="level == 'first'">
        <tabbed-page
            :tabList="tabList"
            @changeTabs="handlerChangeTabs"
            >
        </tabbed-page>
    </template>
    <global-page-back
      v-else
      :detailPageTitle="secondLevelPageTitle"
      @handlerGlobalType="handlerGlobalType">
    </global-page-back>
    <!-- content -->
    <div>
      <!-- 发公告 -->
      <template v-if="activeTab === '0' && show">
        <component
            ref="sendNoticeComp"
            :is="sendNoticeComp"
            :data="detailData"
            :rulesForm="detailData"
            :treeData="treeData"
            :treeData2="treeData2"
            :type="type"
            @success="success"
            @switchComps="switchNoticeComps"
            @backAnnouncementList="backAnnouncementList"
            @handlerDetailClick="handlerDetailClick"
            @setPageInitTabs="setPageInitTabs"
        />
      </template>
      <!-- 发短信 -->
      <template v-if="activeTab === '1' && show">
          <component  @setPageInitTabs="setPageInitTabs" :is="sendMessageComp" :active='2' :message-data="messageData" @switchComps="handleSwitchComps" />
      </template>
      <!-- 发推送 -->
      <template v-if="activeTab === '2' && show">
          <component
            :is="sendPushComp"
            :push-data="pushData"
            @switchComps="handleSwitchPushComps"
            @setPageInitTabs="setPageInitTabs"
          />
      </template>
      <!-- 发推荐 -->
      <interconnection-school-recommend-scroll-wrapper ref="recommend" @setPageInitTabs="setPageInitTabs" @success="success" @switchComps2="switchNoticeComps2" v-if="activeTab === '3' && show && sendRecommendComp == 'InterconnectionSchoolRecommendScrollWrapper'" @changeComps="switchLevelPage" />
      <interconnection-school-noticeSend
          v-if="activeTab === '3' && show && sendRecommendComp == 'InterconnectionSchoolNoticeSend'"
          :rulesForm="detailData"
          :treeData="treeData"
          :treeData2="treeData2"
          :type="type"
          @success="success"
          @switchComps2="switchNoticeComps2"
      ></interconnection-school-noticeSend>
      <!-- 发轮播 -->
      <interconnection-school-image v-if="activeTab === '4' && show"  @setPageInitTabs="setPageInitTabs" ref="rotation" :active='5' @changeData="switchLevelPage"></interconnection-school-image>
    </div>
  </div>
</template>

<script>
import TabbedPage from '@/components/scrollWrapper/Sub/TabbedPage/index.vue'
import GlobalPageBack from '@/components/scrollWrapper/Sub/GlobalPageBack/index.vue';
import {mapState} from "vuex";
import { tabPageHasAuth } from '@/libs/tabbedPageAuth.js';
export default {
  name: "InterconnectionSchoolNotice",
  components: {
    TabbedPage,
    InterconnectionSchoolNoticeScrollWrapper: () => import(/* webpackChunkName:'InterconnectionSchoolNoticeScrollWrapper' */"@/components/scrollWrapper/InterconnectionSchoolNotice"),
    InterconnectionSchoolRecommendScrollWrapper: () => import(/* webpackChunkName:'InterconnectionSchoolRecommendScrollWrapper' */"@/components/scrollWrapper/InterconnectionSchoolRecommend"),
    InterconnectionSchoolPushScrollWrapper: () => import(/* webpackChunkName:'InterconnectionSchoolPushScrollWrapper' */"@/components/scrollWrapper/InterconnectionSchoolPush"),
    InterconnectionSchoolPushGroupManageScrollWrapper: () => import(/* webpackChunkName:'InterconnectionSchoolPushGroupManageScrollWrapper' */"@/components/scrollWrapper/InterconnectionSchoolPushGroupManage"),
    InterconnectionSchoolPushMsgRecordsScrollWrapper: () => import(/* webpackChunkName:'InterconnectionSchoolPushMsgRecordsScrollWrapper' */"@/components/scrollWrapper/InterconnectionSchoolPushMsgRecords"),
    InterconnectionSchoolImage: () => import(/* webpackChunkName:'InterconnectionSchoolImage' */"@/components/scrollWrapper/InterconnectionSchoolImage"),
    InterconnectionSchoolNoticeDetail: () => import(/* webpackChunkName:'InterconnectionSchoolNoticeDetail' */"@/components/scrollWrapper/InterconnectionSchoolNotice/index"),
    InterconnectionSchoolPushListScrollWrapper: () => import(/* webpackChunkName:'InterconnectionSchoolPushListScrollWrapper' */"@/components/scrollWrapper/InterconnectionPush/InterconnectionSchoolPushList.vue"),
    InterconnectionSchoolPushRecordsScrollWrapper: () => import(/* webpackChunkName:'InterconnectionSchoolPushRecordsScrollWrapper' */"@/components/scrollWrapper/InterconnectionPush/InterconnectionSchoolPushRecords.vue"),
    GlobalPageBack,
    InterconnectionSchoolNoticeSend: () => import(/* webpackChunkName:'InterconnectionSchoolNoticeSend' */"@/components/scrollWrapper/InterconnectionSchoolNotice/SendNotice"),
  },
  data() {
    return {
      show: true,
      level: 'first',
      activeTab: '0',
      type: 1,
      tabList: [
        {
          id: '0',
          title: '发公告',
          vHasPermi: ['announcement:all']
        },
        {
          id: '1',
          title: '发短信',
          vHasPermi: ['sendMess:all']
        },
        {
          id: '2',
          title: '发推送',
          vHasPermi: ['sendPush:all']
        },
        {
          id: '3',
          title: '发推荐',
          vHasPermi: ['sendRecommendation:all']
        },
        {
          id: '4',
          title: '发轮播',
          vHasPermi: ['sendBanner:all']
        }
      ],
      // 发公告
      sendNoticeComp: 'InterconnectionSchoolNoticeScrollWrapper',
      // 发短信
      sendMessageComp: 'InterconnectionSchoolPushScrollWrapper',
      messageData: {},
      treeData: [],
      treeData2: [],
      // 发推送
      sendPushComp: 'InterconnectionSchoolPushListScrollWrapper',
      sendRecommendComp: 'InterconnectionSchoolRecommendScrollWrapper',
      pushData: {},
      // 发公告
      announcementDetailsShow: false,
      detailData: {},
      // 返回标题
      secondLevelPageTitle: ''
    }
  },
  computed:{
    ...mapState({
      permissions:(state) => state.permissions
    }),
  },
  created(){
    this.init();
    this.checkauth()
  },
  activated() {
    const {
      sourceType,
      activeTab
    } = this.$route.query
    // 首页跳转过来
    if (sourceType === 'home' && activeTab === '0') {
      this.activeTab = '0'
      this.$router.replace({})
    }
  },
  methods: {
    init () {
        this.tabList = tabPageHasAuth(this.tabList);
        console.log(this.tabList,'this.tabList0-0-')
    },
    checkauth(){
      let res = this.permissions.indexOf('announcement:all');
      let res2 = this.permissions.indexOf('sendMess:all');
      let res3 = this.permissions.indexOf('sendPush:all');
      let res4 = this.permissions.indexOf('sendRecommendation:all');

      setTimeout(() => {
        if (res !== -1) {
          this.activeTab = '0'
        } else if (res2 !== -1) {
          this.activeTab = '1'
        } else if (res3 !== -1) {
          this.activeTab = '2'
        } else if (res4 !== -1) {
          this.activeTab = '3'
        }
        else {
          this.show = false
        }
      }, 200)
    },
    /**
     *
     * *切换tabs相关
     * handlerChangeTabs
     * */
    handlerChangeTabs (data) {
        this.activeTab = data.id;

        this.tabList.forEach((item, index) => {
            if (item.id === data.id) {
                this.$set(item, 'sel', true)
            } else {
                this.$set(item, 'sel', false)
            }
        })
        this.switchLevelPage()
    },
    //切换公告组件
    switchNoticeComps(comp, messageData, treeData, treeData2, type) {
      this.detailData = messageData
      this.treeData = treeData
      this.treeData2 = treeData2
      this.type = type
      this.sendNoticeComp = comp
      this.switchLevelPage()
    },
    /**
     * @Description: 切换发推荐组件
     * @DoWhat: 
     * @UseScenarios: 
     * @Attention: 
     * @Author: pyl
     * @Date: 2024-06-19 16:44:21
     * @param {*} query
     */    
    switchNoticeComps2(query) {
      this.detailData = query.messageData
      this.treeData = query.treeData
      this.treeData2 = query.treeData2
      this.type = query.type
      this.sendRecommendComp = query.comp
      this.secondLevelPageTitle = '发推荐'
      this.$eventDispatch('setGlobalPageType', 'form')
      this.level = 'second'
      // this.switchLevelPage()
    },
    // 切换组件
    handleSwitchComps(comp, messageData) {
      this.messageData = messageData
      this.sendMessageComp = comp
      this.switchLevelPage()
    },
    // 切换发推送组件
    handleSwitchPushComps(comp, pushData) {
      this.pushData = pushData
      this.sendPushComp = comp
      this.switchLevelPage()
    },
    /**
     * @Description: 切换详情数据
     * @DoWhat:
     * @UseScenarios:
     * @Attention:
     * @Author: 武东超
     * @Date: 2023-02-02 16:50:55
     */
    handlerDetailClick (data) {
      console.log(data,'data')
      this.detailData = data;
      this.announcementDetailsShow = !this.announcementDetailsShow;
      this.switchLevelPage()
    },
    /**
     * @Description: 详情页返回
     * @DoWhat:
     * @UseScenarios:
     * @Attention:
     * @Author: 武东超
     * @Date: 2023-02-02 18:39:17
     */
    backAnnouncementList () {
      this.announcementDetailsShow = !this.announcementDetailsShow;
      this.switchLevelPage()
    },
    // 二级页面返回
    handlerGlobalType() {
      if (this.activeTab === '0') {
        if (this.sendNoticeComp == 'InterconnectionSchoolNoticeDetail') { // 发公告
            this.switchNoticeComps('InterconnectionSchoolNoticeScrollWrapper')
        } else if (this.sendNoticeComp == 'InterconnectionSchoolNoticeSend') { // 发公告
            this.switchNoticeComps('InterconnectionSchoolNoticeScrollWrapper')
        }
        // if (this.announcementDetailsShow) { // 公告详情
        //   this.backAnnouncementList()
        // }
      } else if (this.activeTab === '1') {
        if (this.sendMessageComp == 'InterconnectionSchoolPushMsgRecordsScrollWrapper') { // 短信记录
          this.handleSwitchComps("InterconnectionSchoolPushScrollWrapper");
        } else if (this.sendMessageComp == 'InterconnectionSchoolPushGroupManageScrollWrapper') { // 分组管理
          this.handleSwitchComps("InterconnectionSchoolPushScrollWrapper");
        }
      } else if (this.activeTab === '2') { // 发推送
        this.handleSwitchPushComps("InterconnectionSchoolPushListScrollWrapper");
      } else if (this.activeTab === '3') {
        console.log('当前tabs---------', this.sendRecommendComp)
        this.sendRecommendComp = 'InterconnectionSchoolRecommendScrollWrapper'
        this.level = 'first'
        let recommendRef = this.$refs.recommend
        if (recommendRef && recommendRef.currentTabComponent === 'RecommendDetail') { // 推荐详情
          recommendRef.changeComp({
            index: 0,
            data: {}
          })
        }
      } else if (this.activeTab === '4') {
        let rotationRef = this.$refs.rotation
        if (rotationRef && !(rotationRef.imageData === '')) { // 轮播详情
          rotationRef.changeData("")
        }
      }
    },
    // 页面布局切换
    switchLevelPage() {
      if (this.activeTab === '0') {
        if (this.sendNoticeComp == 'InterconnectionSchoolNoticeScrollWrapper') { // 发公告
          this.$eventDispatch('setGlobalPageType', 'list')
          this.level = 'first'
        } else if (this.sendNoticeComp == 'InterconnectionSchoolNoticeDetail') { // 公告详情
          this.secondLevelPageTitle = '公告详情'
          this.$eventDispatch('setGlobalPageType', 'form')
          this.level = 'second'
        } else if (this.sendNoticeComp == 'InterconnectionSchoolNoticeSend') { // 发公告
            this.secondLevelPageTitle = '发公告'
          this.$eventDispatch('setGlobalPageType', 'form')
          this.level = 'second'
        }
      } else if (this.activeTab === '1') {
        if (this.sendMessageComp == 'InterconnectionSchoolPushScrollWrapper') { // 发短信
          this.$eventDispatch('setGlobalPageType', 'list')
          this.level = 'first'
        } else if (this.sendMessageComp == 'InterconnectionSchoolPushMsgRecordsScrollWrapper') { // 短信记录
          this.secondLevelPageTitle = '短信记录'
          this.$eventDispatch('setGlobalPageType', 'form')
          this.level = 'second'
        } else if (this.sendMessageComp == 'InterconnectionSchoolPushGroupManageScrollWrapper') { // 分组管理
          this.secondLevelPageTitle = '分组管理'
          this.$eventDispatch('setGlobalPageType', 'form')
          this.level = 'second'
        }
      } else if (this.activeTab === '2') {
        // 发推送
        if (this.sendPushComp === 'InterconnectionSchoolPushListScrollWrapper') {
          this.$eventDispatch('setGlobalPageType', 'list')
          this.level = 'first'
        } else if (this.sendPushComp === 'InterconnectionSchoolPushRecordsScrollWrapper') {
          this.$eventDispatch('setGlobalPageType', 'form')
          this.secondLevelPageTitle = '推送记录'
          this.level = 'second'
        }
      } else if (this.activeTab === '3') {
        let recommendRef = this.$refs.recommend
        if (recommendRef) {
          if (recommendRef.currentTabComponent === 'RecommendList') { // 发推荐
            this.$eventDispatch('setGlobalPageType', 'list')
            this.level = 'first'
          } else if (recommendRef.currentTabComponent === 'RecommendDetail') { // 推荐详情
            this.secondLevelPageTitle = '推荐详情'
            this.$eventDispatch('setGlobalPageType', 'form')
            this.level = 'second'
          }
        } else {
          this.$eventDispatch('setGlobalPageType', 'list')
          this.level = 'first'
        }
      } else if (this.activeTab === '4') {
        let rotationRef = this.$refs.rotation
        if (rotationRef) {
          if (rotationRef.imageData === '') { // 发轮播
            this.$eventDispatch('setGlobalPageType', 'list')
            this.level = 'first'
          } else { // 轮播详情
            this.secondLevelPageTitle = '轮播详情'
            this.$eventDispatch('setGlobalPageType', 'form')
            this.level = 'second'
          }
        } else {
          this.$eventDispatch('setGlobalPageType', 'list')
          this.level = 'first'
        }
      }
    },
    setPageInitTabs(){
        console.log(this.show ,this.activeTab,this.level,this.announcementDetailsShow)
        this.show = true;
        this.activeTab = '0'
        this.level = 'first';
        this.announcementDetailsShow = false
    },
    /**
     * @Description: 响应子组件事件，进行组件切换并调用发公告或发推荐接口
     * @DoWhat: 
     * @UseScenarios: 
     * @Attention: 
     * @Author: pyl
     * @Date: 2024-06-19 16:45:12
     */    
    success() {
      if (this.activeTab == '0') {
        this.sendNoticeComp = 'InterconnectionSchoolNoticeScrollWrapper'
        this.$nextTick(() => {
          this.level = 'first'
          this.$refs.sendNoticeComp.submit()
        })
      } else if (this.activeTab == '3') {
        this.sendRecommendComp = 'InterconnectionSchoolRecommendScrollWrapper'
        this.$nextTick(() => {
          this.level = 'first'
          this.$refs.recommend.$refs.sonRef.submit()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .interconnection-school-notice-layout {
    .tabs-list {
      display: flex;
      width: 100%;
      height: 48px;
      line-height: 48px;
      background-color: #fff;
      border-radius: 4px 0 0 4px;
      overflow: hidden;

      .tabs-item {
        width: 100px;
        position: relative;
        text-align: center;
        font-size: 14px;
        color: #333333;
        cursor: pointer;
      }

      .tabs-item.current {
          position: relative;
          color: #3c7fff;

          &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 25%;
              width: 50%;
              height: 2px;
              border-radius: 1px;
              background-color: #3c7fff;
          }
      }
    }
  }
</style>
